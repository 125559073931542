<template>
    <div>
        <a-modal title="导出" v-model="visible" centered :closable='isDone?false:true' :maskClosable='isDone?false:true'
            cancelText="取消" okText="保存" width="550px" :footer="null" :zIndex='4000'
            @ok="handleOk" @cancel="handleCancel">
            <div>
                <a-result v-if="isDone" sub-title="导出中...">
                    <template slot="icon">
                        <a-icon type="loading" :style="{fontSize:'28px',color:'#00cca2'}" />
                    </template>
                    <div v-if="resultData" style="text-align:center;margin:10px 0">
                        共 {{resultData.total}} 条数据, 
                        已下载<span style="color:#659ded"> {{resultData.done}} </span>条,
                    </div>
                </a-result>
                <a-result v-if='isSuccess' sub-title="导出完成" status="success"></a-result>
                <a-result v-if='isCancel' sub-title="已取消"></a-result>
                <div v-if="isDone" style="text-align:right">
                    <a-button @click="toCancel" type="primary">取消导出</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name:'export',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                isDone: false,
                isCancel: false,
                isSuccess:false,
                resultData:''
            }
        },
        props:{
            exportData:Object,
            exportInfo:Object
        },
        created () {
            this.visible = true
            this.export()
        },
        methods:{
            async export(){
                this.isDone = true
                let exportData = {
                    ...this.exportData
                }
                await this.$store.dispatch(this.exportInfo.url, exportData)
                .then(res=>{
                    if (res.type.indexOf('json') !== -1) {
                        var blob = new Blob([res], {
                            type: 'application/json'
                        })
                        let reader = new FileReader();
                        reader.readAsText(blob, 'utf-8')
                        reader.onload = () => {
                            var temp = JSON.parse(reader.result)
                            if(temp.status=='error'){
                                this.isCancel = true
                                this.isDone = false
                                this.isSuccess = false
                                this.$message.error(temp.msg);
                                return false;
                            }
                            this.resultData = temp.data
                            if(this.isCancel){
                                temp.data.page = -1
                            }
                            this.toExport(temp.data)
                        }
                    }else{
                        this.downfile(res)
                    }  
                })
                .catch(err=>{
                    console.log(err)
                })
            },
            async toExport(data){
                let exportData = {
                    ...data,
                    ...this.exportData
                }
                await this.$store.dispatch(this.exportInfo.url, exportData)
                .then(res=>{
                    if (res.type.indexOf('json') !== -1) {
                        var blob = new Blob([res], {
                            type: 'application/json'
                        })
                        let reader = new FileReader();
                        reader.readAsText(blob, 'utf-8')
                        reader.onload = () => {
                            var temp = JSON.parse(reader.result)
                            this.resultData = temp.data
                            if(this.isCancel){
                                temp.data.page = -1
                            }
                            this.toExport(temp.data)
                        }
                    }else{
                        this.downfile(res)
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
            },
            downfile(res){
                this.isDone = false
                this.isCancel = false
                this.isSuccess = true
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `${this.exportInfo.name}.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
            },
            toCancel(){
                this.isCancel = true
                this.isDone = false
                this.isSuccess = false
            },
            handleOk(){},
            handleCancel(){
                this.parent.hideExportModal()
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>