<template>
        <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
            cancelText="取消" centered okText="转移" width="400px" :zIndex='4200'
            @ok="handleOk" @cancel="handleCancel">
            <a-form layout="vertical">

                <a-form-item  label="请选择校区">
                    <a-select :not-found-content="fetching ? undefined : null" style="width: 100%" v-model='studio_id' @change="handleChange" @search='handleSearch' showSearch placeholder="请选择校区" :filterOption="filterStudio">
                        <template slot="notFoundContent">
                            <div style="height:50px">
                                <vue-element-loading :active="fetching" color="#00cca2" spinner="spinner"/>
                            </div>
                        </template>
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="变更负责人">
                    <a-select mode="multiple" :not-found-content="fetchingS ? undefined : null" v-model="course_consultant_id" showSearch :filterOption="filterOption" style="width: 100%" :placeholder="studio_id?'请选择':'请先选择校区'">
                        <template slot="notFoundContent">
                            <div style="height:50px">
                                <vue-element-loading :active="fetchingS" color="#00cca2" spinner="spinner"/>
                            </div>
                        </template>
                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">
                            <div>
                                <LImg style="width:24px;height:24px;border-radius:50%;margin-right:8px" :src="d['employee_avatar']"/>
                                <span>{{ d['filter_name'] }}</span>
                            </div>
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item  label="变更渠道">
                    <a-cascader
                    :options="channels"
                    :show-search="{ filterOption }"
                    :allowClear="true"
                    v-decorator="['channel_id', { rules: {required: false} }]"
                    placeholder="请选择"
                    :popupStyle='{zIndex:4200}'
                    @change="onChangeCascader">
                        <template slot="displayRender" slot-scope="{labels}">
                            <span>{{labels[1]}}</span>
                        </template>
                    </a-cascader>
                </a-form-item>

                <a-form-item  label="分配方式">
                    <a-radio-group v-model='assign_type'>
                        <a-radio value="sort_assign">顺序平均</a-radio>
                        <a-radio value="rand_assign">随机平均</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="跟进状态">
                    <a-radio-group v-model='task_step'>
                        <a-radio :value="-1">不做变更</a-radio>
                        <a-radio :value="0">变未跟进</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item  label="转移方式">
                    <a-radio-group v-model='process_all'>
                        <a-radio :value="0">当前选中</a-radio>
                        <a-radio :value="1">全量转移</a-radio>
                    </a-radio-group>
                </a-form-item> 
                <a-form-item  label="线索类型" v-if="isTab==0">
                    <a-radio-group v-model='in_pool'>
                        <a-radio :value="0">当前跟进</a-radio>
                        <a-radio :value="1">线索池</a-radio>
                    </a-radio-group>
                </a-form-item> 

            </a-form>
        </a-modal>
</template>

<script>
    export default {
        name:'transfer',
        inject: ['parent'],
        props:['item','isTab','search','isAll'],
        data() {
            return {
                visible:false,
                fetching:false,
                fetchingS:false,
                ModalTitle: '批量转移',
                confirmLoading:false,
                studios:[],
                consultants:[],
                channels:[],
                channelsId:[],
                studio_id:undefined,
                course_consultant_id:[],
                task_step:-1,
                process_all:0,
                in_pool:0,
                assign_type:'sort_assign'
            }
        },
        created () {
            this.visible = true
            this.getStudio()
            this.getSourceChannel()
        },
        beforeDestroy () {
            this.visible = false
        },
        methods: {
            filterStudio(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            filterOption(input, option) {
                return option.componentOptions.children[0].children[1].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getSourceChannel() {
                this.loading = true
                let res = await this.$store.dispatch('searchSourceChannelCascaderAction', { })
                this.loading = false
                this.channels = res.data[0].children
                // console.log(this.channels)
            },
            async getConsultant(id) {
                this.fetchingS = true
                let res = await this.$store.dispatch('searchConsultantAction', {params:{studio_id:id},data:{active:true}})
                this.consultants = res.data
                this.fetchingS = false
            },
            async getStudio(val) {
                this.fetching = true
                let res = await this.$store.dispatch('searchAllStudioAction', {params:val})
                this.studios = res.data
                this.fetching = false
            },
            async handleOk() {
                if(!this.studio_id){
                    this.$message.warning('请选择校区!')
                    return false
                }
                if(this.course_consultant_id.length == 0){
                    this.$message.warning('请选择负责人!')
                    return false
                }
                this.confirmLoading=true
                let res 
                if(this.isTab == '0'){
                    let obj = {
                        task_id:this.item,
                        employee_id:this.course_consultant_id,
                        studio_id:this.studio_id,
                        channel_id:this.channelsId[1],
                        process_all:this.process_all,
                        search: this.search,
                        task_step:this.task_step,
                        assign_type:this.assign_type,
                        in_pool:this.in_pool
                    }
                    if(this.isAll === 1){
                        obj.process_all = this.process_all
                    }
                    await this.$store.dispatch('cLeadsTransferAction', obj)
                    .then(res=>{
                        this.$message.success('操作成功!')
                        this.visible = false
                        // this.parent.isAll = 0
                        this.parent.hideTransfer(1)
                    })
                    .catch(err=>{
                        // this.parent.isAll = 0
                        this.parent.hideTransfer(1)
                    })
                }else{
                    let task_id = []
                    let customer_id = []
                    this.item.forEach(it=>{
                        task_id.push(it.task_id)
                        customer_id.push(it.customer_id)
                    })
                    let obj = {
                        task_id:task_id,
                        employee_id:this.course_consultant_id,
                        customer_id:customer_id,
                        studio_id:this.studio_id,
                        channel_id:this.channelsId[1],
                        task_step:this.task_step,
                        process_all:this.process_all,
                        assign_type:this.assign_type
                    }
                    await this.$store.dispatch('cCustomerTransferAction', obj)
                    .then(res=>{
                        this.$message.success('操作成功!')
                        this.visible = false
                        // this.parent.isAll = 0
                        this.parent.hideTransfer(1)
                    })
                    .catch(err=>{
                        // this.parent.isAll = 0
                        this.parent.hideTransfer(1)
                    })
                }
            },
            handleCancel(){
                this.parent.isAll = 0
                this.parent.hideTransfer(0)
            },
            onChangeCascader(value, selectedOptions) {
                this.channelsId = value
            },
            handleSearch(e){
                let obj = {q:e}
                this.getStudio(obj)
            },
            handleChange(val){
                this.getConsultant(val)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>